// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}


// .content-header{
//   padding: 10px 0.5rem !important;
// }

.card {
  box-shadow: none !important;
  margin-bottom: 1rem;
}

.nav-item {
  padding-left: 10px !important;
}

a.nav-link {
  padding: 8px 8px !important;
}

.fa-border {
  border: solid 0.08em #eee;
  background-color: #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.shell1 {
  height: 18px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 8px;
  padding: 3px;
}

.bar1 {
  background: linear-gradient(to right, #B993D6, #8CA6DB);
  height: 10px;
  width: 15px;
  border-radius: 4px;
  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}